import { t } from "i18next";
import styled from "styled-components";
import brand from '../../assets/images/food/01.png';
import food1 from '../../assets/images/food/food.png';

export const ModalWrapper = styled.div`
    padding: 1rem;

    & > ul {
        margin-bottom: 1rem;
    }

    & > p {
        white-space: pre-wrap;
    }

    @media screen and (min-width: 1024px) {
        padding: 2rem;
    }
`;

export const MessageWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    padding: 1rem;
    border: 1px solid #eee;
    border-radius: 10px;

    & > h1 {
        font-size: 21px;
        text-align: center;
        line-height: 1.4;
    }

    @media screen and (min-width: 1024px) {
        & > h1 {
            max-width: 60%;
        }
    }
`;

export const ModalHeader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;

    & > img {
        width: 100%;
    }
`;

export const BrandWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    & > img {
        width: 150px;
    }
`;

export const Niraria = () => {
    return (
        <ModalWrapper>
            <BrandWrapper>
                <img src={brand} alt="niraria" />
            </BrandWrapper>
            <p>{t('food.items_desc.0.desc')}</p>
            <ModalHeader>
                <img src={food1} alt="food" />
            </ModalHeader>
            <p>{t('food.items_desc.0.extra_desc.pouch')}</p>
            <ul>
                <li>{t('food.items_desc.0.extra_desc.pouch_list.0')}</li>
                <li>{t('food.items_desc.0.extra_desc.pouch_list.1')}</li>
                <li>{t('food.items_desc.0.extra_desc.pouch_list.2')}</li>
            </ul>
            <p>{t('food.items_desc.0.extra_desc.packaging')}</p>
            <ul>
                <li>{t('food.items_desc.0.extra_desc.packaging_list.0')}</li>
                <li>{t('food.items_desc.0.extra_desc.packaging_list.1')}</li>
                <li>{t('food.items_desc.0.extra_desc.packaging_list.2')}</li>
            </ul>
            <MessageWrapper>
                <h1>{t('food.items_desc.0.extra_desc.liquid')}</h1>
            </MessageWrapper>
        </ModalWrapper>
    )
}