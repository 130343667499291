// ImageSlider.js
import { t } from "i18next";
import React from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import styled from 'styled-components';
import img1 from '../../assets/images/landscape/01.webp';
import img2 from '../../assets/images/landscape/02.webp';
import img3 from '../../assets/images/landscape/03.webp';
import img4 from '../../assets/images/landscape/04.webp';
import img5 from '../../assets/images/landscape/05.webp';
import symbol from '../../assets/images/symbol.png';
import { SectionWrapper } from '../../global';
import { HeaderContainer, IconWrapper, Overlay } from '../../section/hero';

const ImageWrapper = styled.div`
    & > img {
        width: 100%;
        height: 700px;
        object-fit: cover;
    }

    @media screen and (min-width: 768px) {
        height: 100%;
    }
`;

const CarouselWrapper = styled.div`
    position: relative;
    & > div > button {
        display: none;
    }
`;

const HeroContainer = styled.div`
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
`;
const ImageSlider = () => {

    const images = [
        img1, img2, img3, img4, img5
    ];


    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 500, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };
    return (
        <CarouselWrapper>
            <Overlay />
            <Carousel responsive={responsive}
                swipeable={false}
                draggable={false}
                showDots={false}
                ssr={true}
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={1000}
                transitionDuration={2000}
            >
                {images.map((data, index) => <ImageWrapper key={index} >
                    <img src={data} alt={index} />
                </ImageWrapper>)}
            </Carousel>
            <HeroContainer>
                <SectionWrapper>
                    <HeaderContainer data-aos="fade-up">
                        <IconWrapper>
                            <img src={symbol} alt="symbol" />
                        </IconWrapper>
                        <h1>{t('hero.title')}</h1>
                        <p>{t('hero.desc')}</p>
                    </HeaderContainer>
                </SectionWrapper>
            </HeroContainer>
        </CarouselWrapper>
    );
};

export default ImageSlider;
