import { t } from "i18next";
import React from "react";
import brand from '../../assets/images/food/02.png';
import food1 from '../../assets/images/food/food2.png';
import { BrandWrapper, ModalHeader, ModalWrapper } from "../niraria";

export const Asthapadi = () => {
    return (
        <ModalWrapper>
            <BrandWrapper>
                <img src={brand} alt="brand" />
            </BrandWrapper>
            <p>{t('food.items_desc.1.desc')}</p>
            <ModalHeader>
                <img src={food1} alt="food" />
            </ModalHeader>
        </ModalWrapper>
    )
}