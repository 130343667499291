import styled from "styled-components";

export const AnimalGrid = styled.div`
    display: grid;
    gap: 2rem;
    margin-top: 2rem;
`;

export const AnimalFeedCard = styled.div`
    border-top: 1px solid #ddd;

`;

export const AnimalContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    padding: 0 1rem;

    & > img {
        width: 100%;
    }

    @media screen and (min-width: 1024px) {
        flex-direction: row;
        padding: 0;
        & > img {
            width: 700px;
        }
    }
`;

export const AnimalRight = styled.div`
    display: flex;
    flex-direction: column;

    & > h1 {
        font-size: 28px;
        margin-bottom: 8px;
        max-width: 50%;
    }

    & > p {
        line-height: 1.7;
        color: #33333370;
        font-size: 18px;
    }
`;