import { Boat, ChartBar, Leaf, Lightning, UsersThree } from "@phosphor-icons/react";
import { t } from "i18next";
import React from "react";
import { Section, SectionHeaderCentered, SectionWrapper } from "../../global";
import { SyncCard, SyncDoubleGrid, SyncThreeGrid } from "./style";

export const Why = () => {
    return (
        <Section backgroundColor="#fafafc" id="why">
            <SectionWrapper>
                <SectionHeaderCentered data-aos="fade-up">
                    <h1>{t('why.title')}</h1>
                    <h2>{t('why.header')}</h2>
                    <p>{t('why.desc')}</p>
                </SectionHeaderCentered>
                <SyncThreeGrid>
                    <SyncCard data-aos="fade-up">
                        <Leaf size={30} />
                        <span>{t('why.items.0')}</span>
                    </SyncCard>
                    <SyncCard data-aos="fade-up">
                        <Lightning size={30} />
                        <span>{t('why.items.1')}</span>
                    </SyncCard>
                    <SyncCard data-aos="fade-up">
                        <ChartBar size={30} />
                        <span>{t('why.items.2')}</span>
                    </SyncCard>
                </SyncThreeGrid>
                <SyncDoubleGrid>
                    <SyncCard data-aos="fade-up">
                        <Boat size={30} />
                        <span>{t('why.items.3')}</span>
                    </SyncCard>
                    <SyncCard data-aos="fade-up">
                        <UsersThree size={30} />
                        <span>{t('why.items.4')}</span>
                    </SyncCard>
                </SyncDoubleGrid>
            </SectionWrapper>
        </Section>
    )
}