import { Globe, List, X } from "@phosphor-icons/react";
import { t } from "i18next";
import React, { useState } from "react";
import styled from "styled-components";
import logo from '../../assets/images/logo.png';
import i18n from "../../i18n";

const NavbarWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 100;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 4px;
`

export const NavbarContainer = styled.nav`
    padding: 12px 1rem;
    margin: 0 auto;
    max-width: 1180px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > img {
        width: 200px;
    }

    @media screen and (min-width: 1024px) {
        & > img {
            width: 242px;
        }
    }
`;

export const NavbarMenu = styled.div`
    display: none;
    gap: 1.5rem;

    & > a {
        text-decoration: none;
        color: #333;
        font-weight: 600;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }

    @media screen and (min-width: 1024px) {
        display: flex;
    }
`;


const LanguageFlex = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;


    & > select {
        border: 0;
        font-size: 17px;
        font-weight: 500;
        background: transparent;
        color: #333;

        &:active, &:focus {
            outline: none;
        }
    }
`;

const MobileLanguageWrapper = styled.div`
    display:flex;
    align-items: center;
    gap: 8px;

    @media screen and (min-width: 1024px) {
        display: none;
    }
`;

const MenuMobile = styled.div`
    transition: 0.3s ease-in-out;
    position: fixed;
    left: 0;
    right: 0;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 4px;
    opacity: ${({ isOpen }) => (isOpen ? "100%" : "0")};
    top: ${({ isOpen }) => (isOpen ? "0" : "-100%")};
    z-index: 999;
    width: 100%;
`;

const MenuMobileWrapper = styled.div`
    padding: 2rem;
`;

const MenuMobileList = styled.div`
    display: grid;
    gap: 1rem;
    margin-top: 2rem;

    & > a {
        text-decoration: none;
        color: #333;
        font-weight: 500;
    }
`;

export const Navbar = () => {
    const [open, setOpen] = useState(false);
    const changeLanguageHandler = (lang) => {
        console.log(lang);
        if (lang.toLowerCase() === 'english') {
            i18n.changeLanguage('en');
        } else if (lang.toLowerCase() === 'bahasa') {
            i18n.changeLanguage('id');
        }
    };

    return (
        <NavbarWrapper>
            <NavbarContainer>
                <img src={logo} alt="logo" />
                <NavbarMenu>
                    <a href="#about">{t('navbar.aboutUs')}</a>
                    <a href="#products">{t('navbar.ourProducts')}</a>
                    <a href="#why">{t('navbar.whyUs')}</a>
                    <a href="#footer">{t('navbar.contactUs')}</a>
                </NavbarMenu>
                <NavbarMenu>
                    <LanguageFlex>
                        <Globe size={20} />
                        <select onChange={({ currentTarget }) => changeLanguageHandler(currentTarget.value)}>
                            <option value={"english"}>EN</option>
                            <option value={"bahasa"}>ID</option>
                        </select>
                    </LanguageFlex>
                </NavbarMenu>
                <MobileLanguageWrapper>
                    <LanguageFlex>
                        <Globe size={20} />
                        <select onChange={({ currentTarget }) => changeLanguageHandler(currentTarget.value)}>
                            <option value={"english"}>EN</option>
                            <option value={"bahasa"}>ID</option>
                        </select>
                    </LanguageFlex>
                    <List size={20} onClick={() => setOpen(true)} />
                </MobileLanguageWrapper>
                <MenuMobile isOpen={open}>
                    <MenuMobileWrapper>
                        <X size={20} onClick={() => setOpen(false)} />
                        <MenuMobileList>
                            <a href="#about">{t('navbar.aboutUs')}</a>
                            <a href="#products">{t('navbar.ourProducts')}</a>
                            <a href="#why">{t('navbar.whyUs')}</a>
                            <a href="#footer">{t('navbar.contactUs')}</a>
                        </MenuMobileList>
                    </MenuMobileWrapper>
                </MenuMobile>
            </NavbarContainer>
        </NavbarWrapper>
    )
}