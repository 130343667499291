import { t } from "i18next";
import React from "react";
import styled from "styled-components";
import batik from '../../assets/images/batik.png';
import img from '../../assets/images/seafood.png';
import { Section, SectionHeaderLeft, SectionWrapper } from "../../global";
import { ShapeWrapper } from "../about";

const SectionFlex = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (min-width: 1024px) {
        flex-direction: row;
    }
`;

export const Seafood = () => {
    return (
        <Section>
            <ShapeWrapper>
                <img src={batik} alt="shape" />
            </ShapeWrapper>
            <SectionWrapper>
                <SectionFlex>
                    <SectionHeaderLeft data-aos="fade-up">
                        <h1>{t('seafood.title')}</h1>
                        <h2>{t('seafood.header')}</h2>
                        <p>{t('seafood.desc')}</p>
                    </SectionHeaderLeft>
                    <img src={img} alt="Seafood" data-aos="fade-down" />
                </SectionFlex>
            </SectionWrapper>
        </Section>
    )
}