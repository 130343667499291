import { t } from "i18next";
import React, { useState } from "react";
import brand from '../../assets/images/food/01.png';
import brand2 from '../../assets/images/food/02.png';
import food1 from '../../assets/images/food/food.png';
import food2 from '../../assets/images/food/food2.png';
import { ModalComponent } from "../../component/modal";
import { Section, SectionHeaderLeft, SectionWrapper } from "../../global";
import { Asthapadi } from "../../modal/asthapadi";
import { Niraria } from "../../modal/niraria";
import { FoodCard, FoodGrid, LogoWrapper, TitleWrapper } from "./style";

const data = [
    {
        img: food1,
        brand: brand,
        title: 'Niraria'
    },
    {
        img: food2,
        brand: brand2,
        title: 'Asthapadi'
    }
]

export const Food = () => {
    const [nirariaShow, setNirariaShow] = useState(false);
    const [padiShow, setPadiShow] = useState(false);

    return (
        <Section>
            <SectionWrapper>
                <SectionHeaderLeft data-aos="fade-up">
                    <h1>{t('food.title')}</h1>
                    <h2>{t('food.header')}</h2>
                    <p>{t('food.desc')}</p>
                </SectionHeaderLeft>
                <FoodGrid data-aos="fade-down">
                    {data.map((item, index) => <FoodCard onClick={() => {
                        if (index === 0) {
                            setNirariaShow(true);
                        } else {
                            setPadiShow(true);
                        }
                    }} key={index} >
                        <LogoWrapper>
                            <img src={item.brand} alt={item.title} />
                        </LogoWrapper>
                        <img src={item.img} alt={item.title} />
                        <TitleWrapper>
                            <span>{item.title}</span>
                        </TitleWrapper>
                    </FoodCard>)}
                </FoodGrid>
                <ModalComponent
                    show={nirariaShow}
                    onHide={() => setNirariaShow(false)}
                    body={<Niraria />}
                />
                <ModalComponent
                    show={padiShow}
                    onHide={() => setPadiShow(false)}
                    body={<Asthapadi />}
                />
            </SectionWrapper>
        </Section>
    )
}