import { Farm, LightbulbFilament, ShieldCheck, UsersThree } from "@phosphor-icons/react";
import { t } from "i18next";
import React from "react";
import styled from "styled-components";
import img from '../../assets/images/batik.png';
import { Section, SectionHeaderCentered, SectionWrapper } from "../../global";
import { AboutCard, AboutContent, AboutGrid } from "./style";

export const ShapeWrapper = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;

    & > img {
        object-fit: contain;
        object-position: center;
        width: 100%;
    }
`;

export const About = () => {
    const data = [
        {
            icon: <ShieldCheck size={35} color="#3A8C07" />,
            title: t('about.items.0.title'),
            desc: t('about.items.0.desc')
        },
        {
            icon: <LightbulbFilament size={35} color="#3A8C07" />,
            title: t('about.items.1.title'),
            desc: t('about.items.1.desc')
        },
        {
            icon: <UsersThree size={35} color="#3A8C07" />,
            title: t('about.items.2.title'),
            desc: t('about.items.2.desc')
        },
        {
            icon: <Farm size={35} color="#3A8C07" />,
            title: t('about.items.3.title'),
            desc: t('about.items.3.desc')
        },
    ]
    return (
        <Section id="about">
            <ShapeWrapper>
                <img src={img} alt="shape" />
            </ShapeWrapper>
            <SectionWrapper>
                <SectionHeaderCentered data-aos="fade-up">
                    <h1>{t('about.title')}</h1>
                    <h2>PT Kelola Kurnia Alam</h2>
                    <p>{t('about.desc')}</p>
                </SectionHeaderCentered>
                <AboutGrid>
                    {data.map((item, index) => <AboutCard key={index} data-aos="fade-down">
                        <AboutContent>
                            {item.icon}
                            <h1>{item.title}</h1>
                            <p>{item.desc}</p>
                        </AboutContent>
                    </AboutCard>)}
                </AboutGrid>
            </SectionWrapper>
        </Section>
    )
}