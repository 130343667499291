import { ArrowRight, DownloadSimple } from "@phosphor-icons/react";
import { t } from "i18next";
import React from "react";
import styled from "styled-components";
import batik from '../../assets/images/batik.png';
import { Section, SectionHeaderCentered, SectionWrapper } from "../../global";
import { ShapeWrapper } from "../about";

const StepsGrid = styled.div`
    display: grid;
    margin-top: 3rem;
    gap: 5rem;
    padding: 0 1rem;

    @media screen and (min-width: 1024px) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        padding: 0;
    }
`

const StepsCard = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > span {
        background-color: #ebf3e6;
        border-radius: 10px;
        padding: 6px 12px;
        font-size: 14px;
        color: #3a8c07;
        font-weight: 600;
    }

    & > h1 {
        font-size: 20px;
        margin: 1.5rem 0 1rem;
    }

    & > p {
        color: #33333370;
        line-height: 1.7;
        font-size: 17px;
        text-align: center;
    }

     & > a {
        font-size: 16px;
        margin-top: 1rem;
        cursor: pointer;
        text-decoration: none;
        font-weight: 600;
        color: #fff;
        padding: 10px 14px;
        background-color: #3a8c07;
        border-radius: 8px;
        display: flex;
        align-items: center;
        gap: 4px;

        &:visited {
            color: #fff;
        }

        &:hover {
            text-decoration: underline;
        }
     }
`;

const ArrowWrapper = styled.div`
    display: none;
    position: absolute;
    right: -60px;
    top: 30%;

    @media screen and (min-width: 768px) {
        display: block;
    }
`;

const ArrowContainer = styled.div`
    border-radius: 50%;
    background-color: #ebf3e6;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;
`;

export const Collab = () => {
    const data = [
        {
            title: t('collab.items.0.title'),
            desc: t('collab.items.0.desc'),
            step: t('collab.items.0.step'),
            download: t('collab.items.0.download')
        },
        {
            title: t('collab.items.1.title'),
            desc: t('collab.items.1.desc'),
            step: t('collab.items.1.step')
        },
        {
            title: t('collab.items.2.title'),
            desc: t('collab.items.2.desc'),
            step: t('collab.items.2.step')
        },
    ]
    return (
        <Section>
            <ShapeWrapper>
                <img src={batik} alt="shape" />
            </ShapeWrapper>
            <SectionWrapper>
                <SectionHeaderCentered>
                    <h1>{t('collab.title')}</h1>
                    <h2>{t('collab.header')}</h2>
                    <p>{t('collab.desc')}</p>
                </SectionHeaderCentered>
                <StepsGrid>
                    {data.map((item, index) => <StepsCard key={index}>
                        <span>{item.step}</span>
                        <h1>{item.title}</h1>
                        <p>{item.desc}</p>
                        {index !== data.length - 1 ? <ArrowWrapper>
                            <ArrowContainer>
                                <ArrowRight color="#3A8C07" size={20} />
                            </ArrowContainer>
                        </ArrowWrapper> : null}
                        {index === 0 ? <a href="/kka-customer.docx" target="_blank">{item.download} <DownloadSimple size={20} /></a> : null}
                    </StepsCard>)}
                </StepsGrid>
            </SectionWrapper>
        </Section>
    )
}