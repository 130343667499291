import styled from "styled-components";

export const ProductGrid = styled.div`
    display: grid;
    gap: 2rem;
    margin-top: 2rem;
    padding: 0 1rem;

    @media screen and (min-width: 1024px) {
        grid-template-columns: repeat(3, minmax(0, 263px));
        justify-content: center;
        margin-top: 4rem;
        padding: 0;
    }
`;

export const BackShape = styled.div`
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;

    & > img {
        height: 100%;
        width: 100%;
    }
`;

export const ProductCard = styled.div`
    position: relative;
    border-radius: 10px;
    height: 242px;

    & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
    }
`;

export const ProductDesc = styled.div`
    position: absolute;
    z-index: 2;
    left: 0;
    bottom: 0;
    padding: 1.5rem;

    & > h1 {
        font-size: 36px;
        color: #fff;
    }

    & > p {
        font-size: 16px;
        color: #ffffff60;
    }
`;