import { WhatsappLogo } from "@phosphor-icons/react";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from 'react';
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import './App.css';
import ImageSlider from "./component/slider";
import { About } from './section/about';
import { AnimalFood } from './section/animal';
import { Collab } from "./section/collab";
import { Food } from './section/food';
import { Footer } from './section/footer';
import { Navbar } from './section/navbar';
import { Products } from './section/products';
import { Seafood } from './section/seafood';
import { Why } from './section/why';

const BodyWrapper = styled.div`
  position: relative;
`;

const WhatsappWrapper = styled.div`
  position: fixed;
  right: 20px;
  bottom: 20px;
  display: flex;
  gap: 4px;
  z-index: 9999;
`;

const TextWrapper = styled.div`
  background-color: #fff;
  padding: 8px 16px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  align-self: center;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 4px;

  & > p {
    text-align: center;
    margin: 0;
    font-size: 14px;
    font-weight: 500;
  }
`;

const WhatsappIcon = styled.div`
  padding: 8px;
  background-color: #075e54;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const App = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  })
  return (
    <BodyWrapper>
      <Navbar />
      <ImageSlider />
      <About />
      <Products />
      <Food />
      <AnimalFood />
      <Seafood />
      <Why />
      <Collab />
      <Footer />
      <WhatsappWrapper>
        <TextWrapper>
          <p>Need Help? Contact Us Now!</p>
        </TextWrapper>
        <WhatsappIcon>
          <a href="https://wa.me/+62819230890?text=Hi, Saya Tertarik dengan KKA. Boleh dibantu?"><WhatsappLogo size={30} color="#fff" /></a>
        </WhatsappIcon>
      </WhatsappWrapper>
    </BodyWrapper>
  );
}

export default withTranslation()(App);
