import { t } from "i18next";
import React from "react";
import img01 from '../../assets/images/animal/01.png';
import img02 from '../../assets/images/animal/02.png';
import img03 from '../../assets/images/animal/03.png';
import logo from '../../assets/images/ifeed.png';
import { Section, SectionHeaderCentered, SectionWrapper } from "../../global";
import { AnimalContent, AnimalFeedCard, AnimalGrid, AnimalRight } from "./style";

export const AnimalFood = () => {
    const data = [
        {
            title: "Palm Kernel Expeller (PKE)",
            desc: t('animals.items.0.desc'),
            image: img01
        },
        {
            title: "Coconut Meal",
            desc: t('animals.items.1.desc'),
            image: img02
        },
        {
            title: "Blackstrap Molasses",
            desc: t('animals.items.2.desc'),
            image: img03
        },
    ]

    return (
        <Section backgroundColor="#FAFAFC">
            <SectionWrapper>
                <SectionHeaderCentered data-aos="fade-up">
                    <h1>{t('animals.title')}</h1>
                    <h2>{t('animals.header')}</h2>
                    <img src={logo} alt="ifeeds" />
                    <p>{t('animals.desc')}</p>
                </SectionHeaderCentered>
                <AnimalGrid>
                    {data.map((item, index) => <AnimalFeedCard style={{ borderBottom: index === data.length - 1 ? "1px solid #ddd" : "0", paddingBottom: index === data.length - 1 ? "2rem" : "0" }} key={index} data-aos="fade-down">
                        <AnimalContent>
                            <img src={item.image} alt={item.title} />
                            <AnimalRight>
                                <h1>{item.title}</h1>
                                <p>{item.desc}</p>
                            </AnimalRight>
                        </AnimalContent>
                    </AnimalFeedCard>)}
                </AnimalGrid>
            </SectionWrapper>
        </Section>
    )
}