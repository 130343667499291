import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import bg from '../../assets/images/landscape/06.webp';
import symbol from '../../assets/images/symbol.png';
import { SectionWrapper } from "../../global";


export const HeaderWrapper = styled.div`
    background-color: #222631;
    background-image: url(${bg});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 0;
    height: 750px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`;

export const Overlay = styled.div`
    background-color: #333;
    opacity: .7;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
`;

export const IconWrapper = styled.div`
    background-color: #fff;
    padding: 2px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-start;
    margin-bottom: 1.5rem;

    & > img {
        width: 45px;
    }
`;

export const HeaderContainer = styled.div`
    padding: 7rem 1rem 4rem;
    z-index: 999;
    position: relative;
    color: #fff;
    display: flex;
    flex-direction: column;

    & > h1 {
        white-space: pre-wrap;
    }

    & > img {
        width: 100%;
        border-radius: 10px;
    }

    & > p {
        color: #fafafa95;
        font-size: 19px;
        line-height: 1.7;
        margin-top: 10px;
    }

    @media screen and (min-width: 1024px) {
        padding: 8rem 1rem 5rem;

        & > h1 {
            font-size: 48px;
        }

        & > p {
            max-width: 50%;
        }
    }
`;


export const Hero = () => {
    const { t } = useTranslation();
    return (
        <HeaderWrapper>
            <Overlay />
            <SectionWrapper>
                <HeaderContainer data-aos="fade-up">
                    <IconWrapper>
                        <img src={symbol} alt="symbol" />
                    </IconWrapper>
                    <h1>{t('hero.title')}</h1>
                    <p>{t('hero.desc')}</p>
                </HeaderContainer>
            </SectionWrapper>
        </HeaderWrapper>
    )
}