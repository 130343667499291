import styled from "styled-components";

export const FoodGrid = styled.div`
    display: grid;
    gap: 2rem;
    margin-top: 2rem;

    @media screen and (min-width: 1024px) {
        grid-template-columns: repeat(2, minmax(0, 290px));
        justify-content: center;
        margin-top: 2.5rem;
    }
`;

export const FoodCard = styled.div`
    position: relative;
    border-radius: 10px;
    cursor: pointer;
    transition: .6s ease;

    &:hover {
        transform: scale(1.1);
    }

    & > img {
        border-radius: 10px;
        width: 100%;
    }
`;

export const LogoWrapper = styled.div`
    border-radius: 10px;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: #F0EFEB;

    & > img {
        width: 50px;
    }
`;

export const TitleWrapper = styled.div`
    position: absolute;
    left: 15px;
    bottom: 15px;
    background-color: #333333;
    padding: 6px 17px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    & > span {
        color: #fff;
        font-size: 14px;
    }
`;