import styled from "styled-components";

export const AboutGrid = styled.div`
    display: grid;
    gap: 2rem;
    margin-top: 2rem;

    @media screen and (min-width: 1024px) {
        grid-template-columns: repeat(2, minmax(0, 310px));
        justify-content: center;
        margin-top: 2.5rem;
    }
`;

export const AboutCard = styled.div`
    border-radius: 10px;
    background-color: #FAFAFC;
    padding: 2rem;
`;

export const AboutContent = styled.div`
    display: flex;
    flex-direction: column;

    & > h1 {
        font-size: 24px;
        margin: 2rem 0 8px;
        width: 50%;
    }

    & > p {
        color: #1E1E2470;
        font-size: 17px;
        line-height: 1.6;
    }
`;