import { t } from "i18next";
import React from "react";
import styled from "styled-components";
import img01 from '../../assets/images/landscape/03.webp';
import img02 from '../../assets/images/landscape/04.webp';
import img03 from '../../assets/images/landscape/05.webp';
import shape from '../../assets/images/shape.png';
import { Section, SectionHeaderCentered, SectionWrapper } from "../../global";
import { BackShape, ProductCard, ProductDesc, ProductGrid } from "./style";

const Overlay = styled.div`
    background-color: #333;
    opacity: .6;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    border-radius: 10px;
`;

export const Products = () => {

    const data = [
        {
            img: img01,
            title: t('products.items.0.title'),
            desc: t('products.items.0.desc'),
        },
        {
            img: img02,
            title: t('products.items.1.title'),
            desc: t('products.items.1.desc'),
        },
        {
            img: img03,
            title: t('products.items.2.title'),
            desc: t('products.items.2.desc'),
        },
    ]

    return (
        <Section backgroundColor="#fafafc" id="products">
            <BackShape>
                <img src={shape} alt="shape" />
            </BackShape>
            <SectionWrapper>
                <SectionHeaderCentered data-aos="fade-up">
                    <h1>{t('products.title')}</h1>
                    <h2>{t('products.header')}</h2>
                    <p>{t('products.desc')}</p>
                </SectionHeaderCentered>
                <ProductGrid>
                    {data.map((item, index) => <ProductCard data-aos="fade-down">
                        <Overlay />
                        <img src={item.img} alt={item.title} />
                        <ProductDesc>
                            <h1>{item.title}</h1>
                            <p>{item.desc}</p>
                        </ProductDesc>
                    </ProductCard>)}
                </ProductGrid>
            </SectionWrapper>
        </Section>
    )
}