import { InstagramLogo, Phone, WhatsappLogo } from "@phosphor-icons/react";
import { t } from "i18next";
import React from "react";
import img from '../../assets/images/footer.png';
import logo from '../../assets/images/symbol.png';
import { Section, SectionWrapper } from "../../global";
import { IconWrapper } from "../hero";
import { BottomFooter, BottomMobile, Contact, IconFooter, SectionFlex, SectionLeft, SectionRight, SocmedFlex } from "./style";

export const Footer = () => {
    return (
        <Section backgroundColor="#222631" id="footer">
            <IconFooter>
                <img src={img} alt="donut" />
            </IconFooter>
            <SectionWrapper>
                <SectionFlex>
                    <SectionRight>
                        <IconWrapper>
                            <img src={logo} alt="logo" />
                        </IconWrapper>
                        <h1>PT Kelola Kurnia Alam</h1>
                        <p>{t('footer.desc')}</p>
                        <SocmedFlex>
                            <a href="https://instagram.com/kka.official"><InstagramLogo size={30} /></a>
                            <a href="https://wa.me/+62819230890?text=Hi, Saya Tertarik dengan KKA. Boleh dibantu?"><WhatsappLogo size={30} /></a>
                        </SocmedFlex>
                        <BottomFooter>
                            <span>Copyright PT Kelola Kurnia Alam Indonesia. 2024. Created by <a href="https://arklify.com">Arklify</a>.</span>
                        </BottomFooter>
                    </SectionRight>
                    <SectionLeft>
                        <h1>Get in Touch</h1>
                        <Contact>
                            <span><Phone /> +62 21 535 1888</span>
                            <span><Phone /> +62 819 230 890 </span>
                            <a href="mailto:sales@kka.com">sales@ina-kka.com</a>
                        </Contact>
                        <p>Komplek Perkantoran Graha Kencana Blok DC
                            <br />Jl Raya Perjuangan No 88 Kebon Jeruk
                            <br />Jakarta Barat - Indonesia 11530</p>
                    </SectionLeft>
                </SectionFlex>
                <BottomMobile>
                    <span>Copyright PT Kelola Kurnia Alam Indonesia. 2024. Created by <a href="https://arklify.com">Arklify</a>.</span>
                </BottomMobile>
            </SectionWrapper>
        </Section>
    )
}