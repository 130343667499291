import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
    * {
        font-family: 'Plus Jakarta Sans', sans-serif;
        margin: 0;
        padding: 0;
        letter-spacing: -.01em;
    }

    *, *::before, *::after {
        box-sizing: border-box;
    }

    h1, h2, h3, h4 {
        margin: 0;
        padding: 0;
        font-weight: 700;
    }

    body {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    html {
        scroll-behavior: smooth 
    }
`;


export const Section = styled.div`
    background-color: ${({ backgroundColor }) => backgroundColor};
    position: relative;
    padding: 3rem 0;
    position: relative;
    z-index: 0;
    overflow: hidden;

    @media screen and (min-width: 1024px) {
        padding: 6rem 0;
    }
`;

export const SectionWrapper = styled.div`
    margin: 0 auto;
    max-width: 1180px;
    position: relative;
    z-index: 2;
`

export const SectionHeader = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 1rem;

    & > h1 {
        color: #3A8C07;
        font-size: 14px;
        text-transform: uppercase;
    }

    & > h2 {
        font-size: 36px;
        color: #333333;
        text-align: center;
        letter-spacing: -2px;
        white-space: pre-wrap;
    }

    & > p {
        color: #333;
        opacity: .6;
        font-size: 18px;
        line-height: 1.7;
        margin-top: 1rem;
    }

    @media screen and (min-width: 1024px) {
        padding: 0;
        & > p {
            max-width: 60%;
        }

        & > h2 {
            font-size: 47px;
        }
    }
`;

export const SectionHeaderCentered = styled(SectionHeader)`
    justify-content: center;
    align-items: center;

    & > p {
        text-align: center;
    }

    & > img {
        width: 150px;
    }
`;

export const SectionHeaderLeft = styled(SectionHeader)`
    justify-content: center;

    & > h2 {
        text-align: left;
    }
`;